/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import Select from 'react-select';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from "react-router-dom";

interface Champion {
  name: string;
  key: string,
}
interface Skin {
  id: number;
  name: string;
  price: number;
}
type ProductType = {
  id: number;
  name: string;
  region: string;
  category: 'LoL' | 'Outros';
  status: 'INATIVAS' | 'ATIVAS' | 'UNRANKED' | '';
  account_type: 'RANKED' | 'CHAMPIONS' | 'RP' | 'SKINS' | '';
  image: string;
  stock: number;
  price: number;
};
interface SkinReturn {
  id: string;
  num: number;
  name: string;
  chromas: boolean;
  rarity?: string;
}

interface RaritiesData {
  [championKey: string]: SkinRarity[];
}
interface SkinRarity {
  rarity: string;
  skinName: string;
  skinId: number;
  championId: number;
}
interface ChampionData {
  type: string;
  format: string;
  version: string;
  data: {
    [key: string]: {
      id: string;
      key: string;
      name: string;
      title: string;
      image: {
        full: string;
        sprite: string;
        group: string;
        x: number;
        y: number;
        w: number;
        h: number;
      };
      skins: SkinReturn[];
    };
  };
}
const ProductBox: React.FC<ProductType> = ({ id, name, image, status, stock, price }) => {
  const isOutOfStock = stock === 0;
  const [quantity, setQuantity] = useState(1);
  const [email, setEmail] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [paymentProcess, setPaymentProcess] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('pix');
  const [pixPayment, setPixPayment] = useState(false);
  const [qrCode64, setQrCode64] = useState('');
  const [copiaCola, setCopiaCola] = useState('');

  const [championList, setChampionList] = useState<Champion[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [version, setVersion] = useState('');
  const navigate = useNavigate();

  const fetchChampions = async () => {
    try {
      const versionsResponse = await fetch('https://ddragon.leagueoflegends.com/api/versions.json');
      const versionsData: string[] = await versionsResponse.json();
      const latestVersion = versionsData[0];
      setVersion(latestVersion);

      const championsResponse = await fetch(
        `https://ddragon.leagueoflegends.com/cdn/${latestVersion}/data/en_US/champion.json`
      );
      const championsData: { data: Record<string, Champion> } = await championsResponse.json();
      const championList = Object.keys(championsData.data)
        .map((key) => ({
          key: key,
          name: championsData.data[key].name,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
      setChampionList(championList);
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchChampions()
  }, [])


  const handleIncreaseQuantity = () => {
    if (quantity < stock) {
      setQuantity(quantity + 1);
    }
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setPaymentProcess(false);
    setShowModal(false);
    setEmail('')
    setPixPayment(false)
    setQrCode64('')
  };

  const handlePay = () => {
    if (paymentMethod === 'pix') {
      let body: any = {
        product_id: id,
        quantity,
        email,
        type: 'pix'
      };
      if (accountSelected !== 0) {
        body.account_id = accountSelected;
      }
      axios.post('https://api.contasmurf.com/sales/pix', body).then((response) => {
        if (response.status === 201) {
          setPaymentProcess(false)
          setPixPayment(true)
          setQrCode64(response.data.qr_code)
          setCopiaCola(response.data.copia)

          const ws = new WebSocket('wss://api.contasmurf.com.br/ws');

          ws.onopen = () => {
            ws.send(JSON.stringify({ saleId: response.data.sale_id }));
          };

          ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.status === 'Aprovado') {
              handleCloseModal();
              navigate(`/success?value=${price}&external_reference=${response.data.sale_id}`)
              ws.close();
            }
          };
          ws.onerror = (error) => {
          };
          ws.onclose = () => {
          };
        }
      })
    }
  };

  const options = championList.map(champion => ({
    value: champion.key,
    label: champion.name,
  }));

  const [selectedChampion, setSelectedChampion] = useState<any>(null);
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [skins, setSkins] = useState<any[]>([]);

  const handleOpenModalSkin = () => {
    setIsModalOpen(true)
  }
  useEffect(() => {

    const fetchChampionSkins = async () => {
      try {
        const response = await fetch(`https://ddragon.leagueoflegends.com/cdn/${version}/data/en_US/champion/${String(selectedChampion.value).replaceAll("'", "")}.json`);
        const championData: ChampionData = await response.json();

        setSkins(championData['data'][`${String(selectedChampion.value).replaceAll("'", "")}`]['skins'])
      } catch (error) {
      }
    };

    if (selectedChampion) {
      fetchChampionSkins()
    }
  }, [selectedChampion])

  const handleChampionChange = async (selectedOption: any) => {
    setSelectedChampion(selectedOption);
    setLoading(true);

    try {
      const response = await axios.post('https://api.contasmurf.com/products/skins', {
        product_id: id,
        champion_name: selectedOption.value,
      });

      setAccounts(response.data);
    } catch (error) {
      setAccounts([]);
    } finally {
      setLoading(false);
    }
  }; const normalizeSkinName = (name: any) => {
    return name.toLowerCase().trim().replace(/[^a-zA-Z0-9]+/g, '');
  };

  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isModalOpen]);



  const [accountSelected, setAccountSelected] = useState(0)

  const handleSelectAccount = (account_id: number) => {
    setAccountSelected(account_id)
    setIsModalOpen(false)
  }
  return (
    <div className={`bg-[#0E233B] text-white p-2 duration-300  hover:scale-105 rounded-lg shadow-xl m-2 ${isOutOfStock ? 'blur' : ''}`}>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Modal de Compra"
        overlayClassName="fixed inset-0 backdrop-blur-sm flex justify-center items-center z-50 bg-opacity-50"
        className="bg-gray-900 p-8 rounded-xl max-w-6xl w-full mx-auto text-white shadow-xl"
      >
        <div className="flex-start">
          <button onClick={() => setIsModalOpen(false)} className="text-white text-lg">
            <AiOutlineClose />
          </button>
        </div>
        <div>
          <h2 className="text-3xl font-semibold text-center mb-8">Escolha um Campeão</h2>
          <h2 className="text-xl font-semibold text-center mb-8">{name}</h2>
          <div className="mb-6">
            <label htmlFor="championSelect" className="block text-lg font-medium mb-2">
              Selecione um Campeão:
            </label>
            <Select
              id="championSelect"
              value={selectedChampion}
              onChange={handleChampionChange}
              options={options}
              className="text-gray-900"
            />
          </div>
          {selectedChampion && (
            <>
              <div className="flex justify-center mb-6">
                <div className="rounded-full overflow-hidden w-20 h-20">
                  <img
                    src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${selectedChampion.value}.png`}
                    alt={selectedChampion.label}
                    className="object-cover w-full h-full"
                  />
                </div>
              </div>
              <p className="text-center mb-4">Total de contas encontradas: {accounts.length}</p>
              <hr className="mb-6 border-gray-700" />

              {loading ? (
                <p className="text-center">Carregando...</p>
              ) : accounts.length > 0 ? (
                <div className="overflow-auto max-h-96 space-y-4">
                  {accounts.sort((a: any, b: any) => b.skins.length - a.skins.length).map((account: any) => (
                    <div key={account.id} className="bg-gray-800 p-4  rounded-lg shadow">
                      <p className="mb-4">ID: {account.id}</p>
                      <div className="flex space-x-2 overflow-x-auto">
                        {account.skins.map((accountSkinName: any) => {
                          const skinObj = skins.find(skin => normalizeSkinName(skin.name) === normalizeSkinName(accountSkinName));
                          return skinObj ? (
                            <img
                              key={skinObj.id}
                              src={`https://ddragon.leagueoflegends.com/cdn/img/champion/loading/${selectedChampion.value}_${skinObj.num}.jpg`}
                              alt={skinObj.name}
                              className="w-16 h-24 object-cover rounded-lg"
                            />
                          ) : null;
                        })}
                      </div>
                      <div className="text-center mt-4">
                        <button onClick={() => handleSelectAccount(account.id)} className="bg-blue-500 hover:bg-blue-600 text-xs text-white py-2 px-4 rounded-lg transition duration-150 ease-in-out">Escolher Conta</button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-center">Sem resultados encontrados</p>
              )}
            </>
          )}
        </div>
      </Modal>

      {showModal && (
        <div className="rounded-lg">

          {(!pixPayment && !paymentProcess) && (
            <>
              <div className=' text-center'>

                <p className="text-lg mb-4"><strong>Detalhes do Produto</strong></p>
                <p>Produto: <strong>{name}</strong></p>


                <p className='text-sm'>Ao escolher uma skin, só sera possível adquirir uma unidade.</p>

                <button
                  className="bg-[#8F8BF9] text-white px-4 py-2 rounded ml-2 hover:bg-[#07172F]"
                  onClick={() => handleOpenModalSkin()}
                >
                  Escolher Skin
                </button>
                <div className="my-4">

                  {accountSelected > 0 ? (
                    <>
                      <p>Conta Selecionada: {accountSelected}</p>
                      <button
                        className="bg-red-500 text-white text-[0.8rem] font-bold hover:border border-[#8F8BF9] px-4 py-2 rounded hover:bg-red-600 hover:text-[#8F8BF9]"
                        onClick={() => setAccountSelected(0)}
                      >
                        Limpar Seleção
                      </button>
                    </>
                  ) : (
                    <>
                      <p>Quantidade</p>
                      <div className="flex items-center justify-center mt-2">
                        <button
                          className="bg-[#8F8BF9] text-white px-4 py-2 rounded mr-2 hover:bg-[#07172F]"
                          onClick={handleDecreaseQuantity}
                        >
                          -
                        </button>
                        <span className="text-lg font-bold">{quantity}</span>
                        <button
                          className="bg-[#8F8BF9] text-white px-4 py-2 rounded ml-2 hover:bg-[#07172F]"
                          onClick={handleIncreaseQuantity}
                        >
                          +
                        </button>
                      </div>
                    </>
                  )}
                </div>

                <p>Valor Total: <strong>R$ {price * quantity}</strong></p>
              </div>

              <label htmlFor="email" className="block text-sm font-medium text-gray-200 mt-8">Email para Entrega</label>
              <input
                type="text"
                id="email"
                placeholder="Digite seu email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="p-3 rounded w-full h-[2rem] mb-4 bg-transparent border border-white text-white"
              />

              <div className="flex justify-center space-x-2">
                <button
                  className="bg-[#8F8BF9] text-white font-bold hover:border border-[#8F8BF9] px-4 py-2 rounded hover:bg-[#07172F] hover:text-[#8F8BF9]"
                  onClick={() => setPaymentProcess(true)}
                  disabled={isOutOfStock || quantity > stock || quantity <= 0 || !email}
                >
                  Comprar
                </button>

                <button
                  className="bg-[#8F8BF9] text-white font-bold hover:border border-[#8F8BF9] px-4 py-2 rounded hover:bg-[#07172F] hover:text-[#8F8BF9]"
                  onClick={handleCloseModal}
                >
                  Fechar
                </button>
              </div>
            </>
          )}

          {(!pixPayment && paymentProcess) && (
            <>
              <div className='text-center'>
                <p className="text-lg mb-4"><strong>Escolha a Forma de Pagamento</strong></p>
                <p>E-mail que receberá o produto: <strong>{email}</strong></p>
                <br />
                <div className="mb-4 flex flex-col">
                  <label>
                    <input
                      type="radio"
                      name="paymentMethod"
                      value="pix"
                      checked={paymentMethod === 'pix'}
                      onChange={() => setPaymentMethod('pix')}
                    />
                    PIX
                  </label>
                  <br />
                </div>
                <div className="flex justify-center space-x-2">
                  <button
                    className="bg-[#8F8BF9] text-white font-bold hover:border border-[#8F8BF9] px-4 py-2 rounded hover:bg-[#07172F] hover:text-[#8F8BF9]"
                    onClick={handlePay}
                    disabled={isOutOfStock || quantity > stock || quantity <= 0 || !email}
                  >
                    Pagar
                  </button>
                  <button
                    className="bg-[#8F8BF9] text-white font-bold hover:border border-[#8F8BF9] px-4 py-2 rounded hover:bg-[#07172F] hover:text-[#8F8BF9]"
                    onClick={handleCloseModal}
                  >
                    Fechar
                  </button>
                </div>
              </div>
            </>
          )}

          {pixPayment && (
            <>
              <div className='text-center'>
                <p className="text-lg mb-4"><strong>Pague pelo QR Code abaixo</strong></p>
                <img src={`data:image/png;base64,${qrCode64}`} alt="QR Code de Pagamento" />
                <p className="mt-4">
                  Ou pague pelo Pix copia e cola.
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(copiaCola)
                      toast.success('PIX Copiado com Sucesso!')
                    }}
                    className="text-blue-500 hover:text-blue-700 underline"
                  >
                    Clique aqui para copiar
                  </button>
                </p>
              </div>
            </>
          )}

        </div>
      )
      }
      {
        !showModal && (
          <div>

            {image && (
              <img src={image} alt={name} className="rounded-lg" />
            )}
            <div className="p-3 rounded-lg mt-4">
              <div className="p-4 text-center font-bold uppercase text-sm">{name}</div>
              <div className="p-2 bg-[#142E46] font-bold rounded-lg text-center my-2">{status}</div>
              <div className={`p-2 bg-[#142E46] font-bold rounded-lg text-center ${isOutOfStock ? 'blur' : ''}`}>{stock} EM ESTOQUE</div>

              <button
                className={`flex items-center justify-center h-10 bg-[#8F8BF9] text-[#07172F] font-bold p-2 w-full mt-4 text-lg rounded transition duration-300 hover:border border-[#8F8BF9] hover:bg-[#07172F] hover:text-[#8F8BF9] hover:border-[#8F8BF9] transform hover:scale-105`}
                disabled={isOutOfStock}
                onClick={handleOpenModal}
              >
                <span>R$ {price}</span>
              </button>
            </div>
          </div>
        )
      }
    </div >
  );
};

const Products: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<string>('LoL');
  const [selectedRegion, setSelectedRegion] = useState<string>('BR');
  const [selectedType, setSelectedType] = useState<string>('TODAS');
  const [selectedExtra, setSelectedExtra] = useState<string>('EM ESTOQUE');

  const [filteredProducts, setFilteredProducts] = useState<ProductType[]>([]);

  const regions = ["BR", "NA", "EUW", "EUNE", "LAS", "LAN", "RU", "TR", "OCE", "JP", "KR", "TW", "VN", "TH", "SG", "PH"]
  const types = ["TODAS", "RANKED", "CHAMPIONS", "RP", "SKINS"]
  const extras = ["EM ESTOQUE", "ATIVAS", "INATIVAS"]

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [productData, setProductData] = useState<ProductType[]>([])

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    axios.get('https://api.contasmurf.com/products/stock')
      .then((result) => {
        setProductData(result.data);
        setLoading(false)
      })
      .catch(error => {
      });
  }, []);

  useEffect(() => {
    updateFilteredProducts();
  }, [productData, selectedRegion, selectedType, selectedExtra, searchTerm, selectedCategory]);

  const updateFilteredProducts = () => {
    let updatedProducts = productData;

    if (selectedCategory === 'LoL') {
      updatedProducts = updatedProducts.filter((product) => {
        const categoryFilter = product.category === 'LoL';
        const regionFilter = selectedRegion === 'TODAS' || product.region === selectedRegion;
        const statusFilter = (selectedExtra === 'ATIVAS' && product.status === 'ATIVAS') ||
          (selectedExtra === 'INATIVAS' && product.status === 'INATIVAS') ||
          (selectedExtra === 'EM ESTOQUE' && product.stock > 0) ||
          selectedExtra === 'TODAS';
        const typeFilter = selectedType === 'TODAS' || product.account_type === selectedType;
        const searchFilter = searchTerm === '' || product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.account_type.toLowerCase().includes(searchTerm.toLowerCase());

        return categoryFilter && regionFilter && statusFilter && typeFilter && searchFilter;
      });
    } else if (selectedCategory === 'Outros') {
      updatedProducts = updatedProducts.filter((product) => {
        const categoryFilter = product.category !== 'LoL';
        const searchFilter = searchTerm === '' || product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.account_type.toLowerCase().includes(searchTerm.toLowerCase());

        return categoryFilter && searchFilter;
      });
    }

    setFilteredProducts(updatedProducts);
  };

  const handleResetFilters = () => {
    setSelectedCategory('');
    setSelectedRegion('BR');
    setSelectedType('TODAS');
    setSelectedExtra('EM ESTOQUE');
  };

  const handleRegionChange = (region: string) => {
    setSelectedRegion(region);
  };

  const handleTypeChange = (type: string) => {
    setSelectedType(type);
  };

  const handleExtraChange = (extra: string) => {
    setSelectedExtra(extra);
  };

  const handleCategoryChange = (category: string) => {
    setSelectedCategory(category);
    setSearchTerm('');
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
  };

  return (
    <div id="products" className="relative bg-[#07172f] w-full flex flex-col items-center mt-16 p-8">
      <div className="flex gap-4 flex-col mb-7 md:flex-row md:justify-between md:w-3/4 md:h-14">
        <button
          className={`text-2xl flex-1 font-bold rounded hover:border transition duration-300 mr-2 ${selectedCategory === 'LoL' ? 'bg-[#07172F] text-[#8F8BF9] border border-[#8F8BF9]' : ' bg-[#8F8BF9] text-[#07172F] border-[#07172F] '
            }`}
          onClick={() => handleCategoryChange('LoL')}
        >
          LEAGUE OF LEGENDS
        </button>
        <button
          className={`text-2xl flex-1 font-bold rounded hover:border transition duration-300 mr-2 ${selectedCategory === 'Outros' ? 'bg-[#07172F] text-[#8F8BF9] border border-[#8F8BF9]' : ' bg-[#8F8BF9] text-[#07172F] border-[#07172F] '
            }`}
          onClick={() => handleCategoryChange('Outros')}
        >
          OUTROS
        </button>
      </div>
      <div className="w-full md:w-3/4 mb-4">
        <input
          type="text"
          placeholder="Procurar produto por nome..."
          className="p-3 rounded w-full text-lg"
          style={{ background: 'rgba(255, 255, 255, 0.2)', color: 'white', border: '2px solid ##8F8BF9' }}
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>

      {selectedCategory === 'LoL' && (

        <div className="flex gap-2 flex-col w-3/4 mb-7 md:flex-row md:h-14 md:mt-8">
          {regions.map((region, index) => (
            <button
              className={`text-2xl flex-1 font-bold rounded hover:border transition duration-300 mr-2 ${selectedRegion === region ? 'border bg-[#07172F] text-[#8F8BF9] border-[#8F8BF9]' : ' bg-[#8F8BF9] text-[#07172F] border-[#07172F] '
                }`}
              onClick={() => handleRegionChange(region)}>
              {region}
            </button>
          ))}
        </div>
      )}
      {selectedCategory === 'LoL' && (

        <div className="flex flex-col w-3/4 mb-7 md:flex-row md:h-14">
          {types.map((type, index) => (
            <button
              className={`text-lg md:text-2xl flex-1 font-bold rounded hover:border transition duration-300 mb-2 md:mr-2 ${selectedType === type ? 'border bg-[#07172F] text-[#8F8BF9] border-[#8F8BF9]' : ' bg-[#8F8BF9] text-[#07172F] border-[#07172F] '
                }`}
              onClick={() => handleTypeChange(type)}>
              {type}
            </button>
          ))}
        </div>
      )}
      {selectedCategory === 'LoL' && (

        <div className="flex flex-col w-3/4 mb-7 md:flex-row md:h-14">
          {extras.map((extra, index) => (
            <button
              className={`text-lg md:text-2xl flex-1 font-bold rounded hover:border transition duration-300 mb-2 md:mr-2 ${selectedExtra === extra ? 'border bg-[#07172F] text-[#8F8BF9] border-[#8F8BF9]' : ' bg-[#8F8BF9] text-[#07172F] border-[#07172F] '
                }`}
              onClick={() => handleExtraChange(extra)}>
              {extra}
            </button>
          ))}
        </div>
      )}
      <>
        {filteredProducts.length === 0 ? (

          <>
            {loading ? (
              <p className="text-white font-bold text-2xl text-center mt-8">Carregando os produtos, aguarde...</p>
            ): (
          <p className="text-white font-bold text-2xl text-center mt-8">
            Não encontramos nenhum produto que corresponda aos filtros selecionados.<br />
            Tente fazer uma seleção diferente ou
            <span className="cursor-pointer text-purple-500" onClick={() => handleResetFilters()}> clique para resetar os filtros.</span>
          </p>

            )}
          </>
        ) : (
          <div className='grid grid-cols-1 md:grid-cols-5 gap-4 w-4/5 mt-8'>
            {filteredProducts.map((product, index) => (
              <ProductBox key={index} {...product} />
            ))}
          </div>
        )}
      </>
    </div>
  );
};

export default Products;