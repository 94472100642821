import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { FaEdit, FaTrash } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import axios from "axios";
import "./styles.css";
import { useNavigate } from "react-router-dom";

interface TableData {
    id: number;
    title: string;
    content: string;
    creator_name: string;
    time_to_read: number;
    image: string;
    created_at: string;
}

const AdminPosts: React.FC = () => {
    const navigate = useNavigate();

    const [showEditModal, setShowEditModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [currentItem, setCurrentItem] = useState<TableData | null>(null);
    const [data, setData] = useState<TableData[]>([]);

    const [tituloAdd, setTituloAdd] = useState('')
    const [conteudoAdd, setConteudoAdd] = useState('')
    const [criadorAdd, setCriadorAdd] = useState('')
    const [tempoAdd, setTempoAdd] = useState(0)
    const [imagemAdd, setImagemAdd] = useState('')

    const removeCookie = (name: string) => {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
      };  
      useEffect(() => {
        const checkToken = async () => {
          const token = document.cookie.split('; ').find(row => row.startsWith('userToken='));
          if (token) {
            try {
              const tokenValue = token.split('=')[1];
              const result = await axios.post('https://api.contasmurf.com/token/validate', { token: tokenValue });
    
              if (result.status !== 200) {
                removeCookie('userToken')
                navigate('/admin')
              }
            } catch (error) {
              removeCookie('userToken')
              navigate('/admin')
            }
          }else{
            navigate('/admin')
          }
        };
        checkToken();
      }, []);

    const token = document.cookie.split('; ').find(row => row.startsWith('userToken='))
    const tokenValue = token?.split('=')[1]

    const config = {
        headers: { Authorization: `Bearer ${tokenValue}` }
    };
    
    useEffect(() => {
        axios.get('https://api.contasmurf.com/posts').then((result) => {
            setData(result.data)
        })
    }, [])

    const handleEdit = (item: any) => {
        setCurrentItem(item);
        setShowEditModal(true);
    };

    const handleDelete = (item: any) => {
        setCurrentItem(item);
        setShowDeleteModal(true);
    };

    const handleSaveEdit = () => {
        axios.patch(`https://api.contasmurf.com/posts/${currentItem?.id}`, {
            title: currentItem?.title,
            creator_name: currentItem?.creator_name,
            time_to_read: currentItem?.time_to_read,
            image: currentItem?.image,
            content: currentItem?.content,
        }, config).then((response) => {
            if (response.status === 200) {
                setShowEditModal(false);
                setCurrentItem(null)
                toast.success("Post editado com sucesso!");
                axios.get('https://api.contasmurf.com/posts').then((result) => {
                    setData(result.data)
                })
            } else {
                toast.error("Um erro ocorreu ao atualizar (STATUS: " + response.status + ")")
            }
        })
    };
    const handleSaveAdd = () => {
        if (tituloAdd && conteudoAdd && imagemAdd && tempoAdd && criadorAdd) {
            axios.post('https://api.contasmurf.com/posts', {
                title: tituloAdd,
                creator_name: criadorAdd,
                time_to_read: tempoAdd,
                image: imagemAdd,
                content: conteudoAdd
            }, config).then((response) => {
                if (response.status === 201) {
                    setTituloAdd('')
                    setCriadorAdd('')
                    setTempoAdd(0)
                    setImagemAdd('')
                    setConteudoAdd('')
                    setShowAddModal(false);
                    toast.success("Post adicionado com sucesso!");
                    axios.get('https://api.contasmurf.com/posts').then((result) => {
                        setData(result.data)
                    })
                } else {
                    toast.error("Um erro ocorreu ao adicionar (STATUS: " + response.status + ")")
                }
            })
        } else {
            toast.error("Você deve preencher todos os campos para adicionar um post!")
        }
    };
    const confirmDelete = () => {
        axios.delete(`https://api.contasmurf.com/posts/${currentItem?.id}`, config).then((result) => {
            if (result.status === 204) {
                toast.error("Post excluído com sucesso!");
                setData(data.filter(item => item.id !== currentItem?.id))
            } else {
                toast.error("Erro ao excluir o Post!");
            }
        })
        setCurrentItem(null)
        setShowDeleteModal(false)
    };
    function handleFileChange(event: any) {
        const file = event.target.files[0];
        if (file.type !== 'image/png' && file.type !== 'image/gif') {
            toast.error('Por favor, selecione um arquivo PNG ou GIF.');
            event.target.value = '';
        } else {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagemAdd(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    }

    function handleFileChangeEdit(event: any) {
        const file = event.target.files[0];
        if (file.type !== 'image/png' && file.type !== 'image/gif') {
            toast.error('Por favor, selecione um arquivo PNG ou GIF.');
            event.target.value = '';
        } else {
            const reader = new FileReader();
            reader.onloadend = () => {
                if (currentItem) {
                    const base64 = reader.result as string;
                    if (base64) {
                        setCurrentItem({ ...currentItem, image: base64 });
                    }
                }
            }
            reader.readAsDataURL(file);
        }
    };

    const columns = [

        {
            name: "ID",

            selector: (row: TableData) => row.id.toString(),
        },
        {
            name: "Título",
            selector: (row: TableData) => row.title,
        },
        {
            name: "Conteúdo",
            cell: (row: TableData) => (
                <div
                    style={{
                        fontSize: '12px',
                        whiteSpace: 'normal',
                        maxWidth: '500px',
                    }}
                    dangerouslySetInnerHTML={{ __html: row.content.length > 80 ? row.content.substring(0, 80).replaceAll("</br>", "\n") + "..." : row.content.replaceAll("</br>", "\n") }}
                />
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            grow: 2,
        },

        {
            name: "Criado Por",
            selector: (row: TableData) => row.creator_name,
        },
        {
            name: "Tempo de Leitura",
            selector: (row: TableData) => row.time_to_read.toString() + " min",
        },
        {
            name: "Imagem",
            cell: (row: TableData) => <img src={`${row.image}`} alt={row.title} style={{ width: '60px', height: '60px' }} />,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: "Data de Criação",
            selector: (row: TableData) => {
                const dateString = row.created_at;
                const date = new Date(dateString);
                const formattedDate = `${("0" + date.getDate()).slice(-2)}/${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()} ${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}`;
                return formattedDate;
            },
        },
        {
            name: "Ações",
            cell: (row: TableData) => (
                <div className="space-x-2">
                    <button
                        onClick={() => handleEdit(row)}
                        className="text-blue-600 hover:text-blue-800"
                    >
                        <FaEdit />
                    </button>
                    <button
                        onClick={() => handleDelete(row)}
                        className="text-red-600 hover:text-red-800"
                    >
                        <FaTrash />
                    </button>
                </div>
            ),
        },
    ];

    const [searchText, setSearchText] = useState("");

    const filteredData = data.filter((item: TableData) =>
        item.title.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <div className="w-full h-full flex flex-col items-center">
            {/* Modal de Inclusão */}
            <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar Post</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formPost">
                            <Form.Label>Título</Form.Label>
                            <Form.Control onChange={(e) => setTituloAdd(e.target.value)} defaultValue={tituloAdd} type="text" placeholder="Titulo" />
                            <Form.Group className="mb-3" controlId="formPostContent">
                                <Form.Label>Conteúdo do Post</Form.Label>
                                <Form.Control
                                    onChange={(e) => setConteudoAdd(String(e.target.value).replaceAll("\n", "</br>"))} defaultValue={String(conteudoAdd).replaceAll("</br>", "\n")}
                                    as="textarea"
                                    placeholder="Conteúdo do post"
                                    rows={5}
                                    style={{
                                        fontSize: '14px',
                                    }}
                                />
                            </Form.Group>
                            <Form.Label>Criador do Post</Form.Label>
                            <Form.Control onChange={(e) => setCriadorAdd(e.target.value)} defaultValue={criadorAdd} type="text" placeholder="Nome do criador" />

                            <Form.Label>Tempo para Leitura</Form.Label>
                            <Form.Control onChange={(e) => setTempoAdd(Number.parseInt(e.target.value))} defaultValue={tempoAdd} type="number" placeholder="Tempo para leitura" />

                            <Form.Group className="mb-3" controlId="formPostImage">
                                <Form.Label>Imagem do Post</Form.Label>
                                <Form.Control type="file" accept="image/png, image/gif" onChange={handleFileChange} />
                            </Form.Group>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAddModal(false)}>Cancelar</Button>
                    <Button variant="primary" onClick={handleSaveAdd}>Adicionar Produto</Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de Edição */}
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Post</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formPost">
                            <Form.Label>Título</Form.Label>
                            <Form.Control onChange={(e) => {
                                if (currentItem) {
                                    setCurrentItem({ ...currentItem, title: e.target.value });
                                }
                            }}
                                type="text" placeholder="Titulo" defaultValue={currentItem?.title} />

                            <Form.Group className="mb-3" controlId="formPostContent">
                                <Form.Label>Conteúdo do Post</Form.Label>
                                <Form.Control
                                    onChange={(e) => {
                                        if (currentItem) {
                                            setCurrentItem({ ...currentItem, content: String(e.target.value).replaceAll("\n", "</br>") });
                                        }
                                    }}
                                    as="textarea"
                                    placeholder="Conteúdo do post"
                                    defaultValue={String(currentItem?.content).replaceAll("</br>", "\n")}
                                    rows={5}
                                    style={{
                                        fontSize: '14px',
                                    }}
                                />
                            </Form.Group>

                            <Form.Label>Criador do Post</Form.Label>
                            <Form.Control onChange={(e) => {
                                if (currentItem) {
                                    setCurrentItem({ ...currentItem, creator_name: e.target.value });
                                }
                            }} type="text" placeholder="Nome do criador" defaultValue={currentItem?.creator_name} />

                            <Form.Label>Tempo para Leitura</Form.Label>
                            <Form.Control onChange={(e) => {
                                if (currentItem) {
                                    setCurrentItem({ ...currentItem, time_to_read: Number.parseInt(e.target.value) });
                                }
                            }} type="number" placeholder="Tempo para leitura" defaultValue={currentItem?.time_to_read} />

                            <Form.Group className="mb-3" controlId="formPostImage">

                                <Form.Group className="mb-3" controlId="formPostImage">
                                    <Form.Label>Imagem do Post</Form.Label>
                                    <Form.Control type="file" accept="image/png, image/gif" onChange={handleFileChangeEdit} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formPostImagePreview">
                                    <Form.Label>Imagem Atual do Post</Form.Label>
                                    <div>
                                        {currentItem?.image && (
                                            <img src={`${currentItem.image}`} alt="Imagem Atual" style={{ width: '100px', height: '100px' }} />
                                        )}
                                    </div>
                                </Form.Group>

                            </Form.Group>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>Cancelar</Button>
                    <Button variant="primary" onClick={handleSaveEdit}>Salvar Alterações</Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de Confirmação de Exclusão */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmação de Exclusão</Modal.Title>
                </Modal.Header>
                <Modal.Body>Tem certeza que deseja excluir esse post?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Não</Button>
                    <Button variant="danger" onClick={confirmDelete}>Sim</Button>
                </Modal.Footer>
            </Modal>

            <h1 className="text-3xl text-black font-semibold mb-4">Posts</h1>
            <input
                type="text"
                placeholder="Pesquisar por título do post"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
            />
            <div className="w-full">
                <Button onClick={() => setShowAddModal(true)}>+ Novo Post</Button>
                <DataTable
                    title="Lista de Posts do Blog"
                    columns={columns}
                    data={filteredData}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 20, 50]}
                    customStyles={{
                        rows: {
                            style: {
                                backgroundColor: 'transparent',
                            },
                        },
                        headCells: {
                            style: {
                                backgroundColor: 'transparent',
                            },
                        },
                    }}
                />


            </div>
        </div>
    );

};

export default AdminPosts;
