import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { FaEdit, FaTrash } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import axios from "axios";
import "./styles.css";
import { useNavigate } from "react-router-dom";

interface TableData {
    id: number;
    item_to_delivery: string;
    sale_id: number;
    created_at: string;
    updated_at: string;
    product: Product
}
interface Product {
    id: number;
    name: string;
}
interface Sale {
    id: number;
}

const AdminStock: React.FC = () => {
    const navigate = useNavigate();

    const [showEditModal, setShowEditModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showImportModal, setShowImportModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [currentItem, setCurrentItem] = useState<TableData | null>(null);
    const [isImportFormValid, setIsImportFormValid] = useState(true);

    const [produtoAdd, setProdutoAdd] = useState(0);
    const [itemAdd, setItemAdd] = useState("");

    const [data, setData] = useState<TableData[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [sales, setSales] = useState<Sale[]>([]);

    const removeCookie = (name: string) => {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
      };  
      useEffect(() => {
        const checkToken = async () => {
          const token = document.cookie.split('; ').find(row => row.startsWith('userToken='));
          if (token) {
            try {
              const tokenValue = token.split('=')[1];
              const result = await axios.post('https://api.contasmurf.com/token/validate', { token: tokenValue });
    
              if (result.status !== 200) {
                removeCookie('userToken')
                navigate('/admin')
              }
            } catch (error) {
              removeCookie('userToken')
              navigate('/admin')
            }
          }else{
            navigate('/admin')
          }
        };
        checkToken();
      }, []);

    const token = document.cookie.split('; ').find(row => row.startsWith('userToken='))
    const tokenValue = token?.split('=')[1]

    const config = {
        headers: { Authorization: `Bearer ${tokenValue}` }
    };
    
    useEffect(() => {
        axios.get('https://api.contasmurf.com/products', config).then((result) => {
            setProducts(result.data)
        })
        axios.get('https://api.contasmurf.com/stocks', config).then((result) => {
            setData(result.data)
        })
        axios.get('https://api.contasmurf.com/sales', config).then((result) => {
            setSales(result.data)
        })
    }, [])


    const handleEdit = (item: any) => {
        setCurrentItem(item);
        setShowEditModal(true);
    };
    const handleProductChange = (event: any) => {
        setSelectedProduct(event.target.value);
    };
    const handleDelete = (item: any) => {
        setCurrentItem(item);
        setShowDeleteModal(true);
    };

    const handleSaveEdit = () => {
        const requestBody = {
            item_to_delivery: currentItem?.item_to_delivery,
            product_id: currentItem?.product.id,
            sale_id: currentItem?.sale_id || null,
        };

        axios.patch(`https://api.contasmurf.com/stocks/${currentItem?.id}`, requestBody, config)
            .then((response) => {
                if (response.status === 200) {
                    setShowEditModal(false);
                    setCurrentItem(null);
                    toast.success("Estoque editado com sucesso!");
                    axios.get('https://api.contasmurf.com/stocks', config)
                        .then((result) => {
                            setData(result.data);
                        });
                } else {
                    toast.error("Um erro ocorreu ao atualizar (STATUS: " + response.status + ")");
                }
            })
            .catch((error) => {
                toast.error("Erro na atualização: " + error.message);
            });
    };

    const handleSaveAdd = () => {
        if ((itemAdd) && (produtoAdd !== 0)) {

            axios.post('https://api.contasmurf.com/stocks', {
                product_id: produtoAdd,
                item_to_delivery: itemAdd,
            }, config).then((response) => {
                if (response.status === 201) {
                    setItemAdd('')
                    setProdutoAdd(0)
                    setShowAddModal(false);
                    toast.success("Estoque adicionado com sucesso!");
                    axios.get('https://api.contasmurf.com/stocks', config).then((result) => {
                        setData(result.data)
                    })
                } else {
                    toast.error("Um erro ocorreu ao adicionar (STATUS: " + response.status + ")")
                }
            })
        } else {
            toast.error("Você deve preencher todos os campos para adicionar um produto!")
        }
    };
    const confirmDelete = () => {
        axios.delete(`https://api.contasmurf.com/stocks/${currentItem?.id}`, config).then((result) => {
            if (result.status === 204) {
                toast.error("Estoque excluído com sucesso!");
                setData(data.filter(item => item.id !== currentItem?.id))
            } else {
                toast.error("Erro ao excluir o estoque!");
            }
        })
        setCurrentItem(null)
        setShowDeleteModal(false)
    };

    const [selectedProduct, setSelectedProduct] = useState('');
    const [fileContent, setFileContent] = useState<string>('');
    const [itemsSummary, setItemsSummary] = useState<string>('');

    const handleSaveImport = () => {
        if (selectedProduct === '') {
            toast.error("Você deve especificar um produto!");
            return;
        }
        if (fileContent === '') {
            setIsImportFormValid(false);
        } else {
            const lines = fileContent.trim().split('\n');
            const stockItems = lines.map(line => ({
                product_id: Number.parseInt(selectedProduct),
                item_to_delivery: line,
            }));

            setShowImportModal(false);
            toast.info(`Importando um total de ${lines.length}, aguarde...`)
            setItemsSummary('');
            setFileContent('');
            setSelectedProduct('')
            axios.post('https://api.contasmurf.com/stocks', stockItems, config).then((response) => {
                if (response.status === 201) {
                    setIsImportFormValid(true);
                    toast.success(`Um total de ${lines.length} itens foram adicionados ao estoque!`);

                    axios.get('https://api.contasmurf.com/stocks', config).then((result) => {
                        setData(result.data);
                    });
                } else {
                    toast.error("Um erro ocorreu ao adicionar (STATUS: " + response.status + ")");
                }
            }).catch(error => {
                toast.error("Erro ao adicionar itens ao estoque: " + error.message);
            });

        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (file) {
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
                const content = e.target?.result;
                if (typeof content === 'string') {
                    setFileContent(content);
                    setIsImportFormValid(true);
                    const lines = content.trim().split('\n');
                    setItemsSummary(`Total de ${lines.length} itens para serem adicionados no estoque.`);
                }
            };
            reader.readAsText(file);
        } else {
            setItemsSummary('')
            setFileContent('')
        }
    };

    const columns = [
        {
            name: "ID",
            selector: (row: TableData) => row.id.toString(),
        },
        {
            name: "Nome do Produto",
            selector: (row: TableData) => row.product.name,
        },
        {
            name: "Item para Entrega",
            selector: (row: TableData) => row.item_to_delivery.length > 30 ? row.item_to_delivery.substring(0, 30) + "..." : row.item_to_delivery,
        },
        {
            name: "ID da Venda",
            selector: (row: TableData) => row.sale_id,
        },
        {
            name: "Data de Criação",
            selector: (row: TableData) => {
                const dateString = row.created_at;
                const date = new Date(dateString);
                const formattedDate = `${("0" + date.getDate()).slice(-2)}/${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()} ${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}`;
                return formattedDate;
            },
        },
        {
            name: "Ações",
            cell: (row: TableData) => (
                <div className="space-x-2">
                    <button
                        onClick={() => handleEdit(row)}
                        className="text-blue-600 hover:text-blue-800"
                    >
                        <FaEdit />
                    </button>
                    <button
                        onClick={() => handleDelete(row)}
                        className="text-red-600 hover:text-red-800"
                    >
                        <FaTrash />
                    </button>
                </div>
            ),
        },
    ];

    const [searchText, setSearchText] = useState("");

    const filteredData = data.filter((item: TableData) =>
        item.product.name.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <div className="w-full h-full flex flex-col items-center">

            <Modal show={showImportModal} onHide={() => setShowImportModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Importar Estoque</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formEstoque">
                            <Form.Label>Produto</Form.Label>
                            <Form.Select value={selectedProduct} onChange={handleProductChange}>
                                <option value=""></option>
                                {products.map((product) => (
                                    <option key={product.id} value={product.id}>{product.name}</option>
                                ))}
                            </Form.Select>

                            <Form.Label>Arquivo para Import</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange} />
                        </Form.Group>
                        {itemsSummary}
                    </Form>
                    {!isImportFormValid && <div style={{ color: 'red' }}>Por favor, selecione um arquivo para importar.</div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowImportModal(false)}>Cancelar</Button>
                    <Button variant="primary" onClick={handleSaveImport}>Importar Estoque</Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de Inclusão */}
            <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar Estoque</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {/* Exemplo de campo preenchido - adapte conforme necessário */}
                        <Form.Group className="mb-3" controlId="formEstoque">
                            <Form.Label>Produto</Form.Label>
                            <Form.Select onChange={(e) => setProdutoAdd(Number.parseInt(e.target.value))}>
                                <option value=""></option>
                                {products.map((product) => (
                                    <option key={product.id} value={product.id}>{product.name}</option>
                                ))}
                            </Form.Select>

                            <Form.Label>Item para Entrega</Form.Label>
                            <Form.Control onChange={(e) => setItemAdd(e.target.value)} type="text" defaultValue={itemAdd} placeholder="Item para Entrega" />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAddModal(false)}>Cancelar</Button>
                    <Button variant="primary" onClick={handleSaveAdd}>Adicionar Estoque</Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de Edição */}
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Estoque</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {/* Exemplo de campo preenchido - adapte conforme necessário */}
                        <Form.Group className="mb-3" controlId="formProductName">
                            <Form.Label>Produto</Form.Label>
                            <Form.Select
                                onChange={(e) => {
                                    const selectedProduct = products.find(product => product.name === e.target.value);
                                    if (currentItem && selectedProduct) {
                                        setCurrentItem({
                                            ...currentItem,
                                            product: { ...currentItem.product, id: selectedProduct.id }
                                        });
                                    }
                                }}
                                defaultValue={currentItem?.product.name || ''}
                            >
                                {products.map((product) => (
                                    <option key={product.id} value={product.name}>{product.name}</option>
                                ))}
                            </Form.Select>

                            <Form.Label>Item para Entrega</Form.Label>
                            <Form.Control onChange={(e) => {
                                if (currentItem) {
                                    setCurrentItem({ ...currentItem, item_to_delivery: e.target.value });
                                }
                            }} type="text" placeholder="Item para Entrega" defaultValue={currentItem?.item_to_delivery || ''} />

                            <Form.Label>Venda</Form.Label>
                            <Form.Select onChange={(e) => {
                                if (currentItem) {
                                    setCurrentItem({ ...currentItem, sale_id: Number.parseInt(e.target.value) });
                                }
                            }} defaultValue={currentItem?.sale_id || ''}>
                                <option value=''></option>
                                {sales.map((sale) => (
                                    <option key={sale.id} value={sale.id}>{sale.id}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        {/* Adicione mais campos conforme necessário */}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>Cancelar</Button>
                    <Button variant="primary" onClick={handleSaveEdit}>Salvar Alterações</Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de Confirmação de Exclusão */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmação de Exclusão</Modal.Title>
                </Modal.Header>
                <Modal.Body>Tem certeza que deseja excluir esse estoque?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Não</Button>
                    <Button variant="danger" onClick={confirmDelete}>Sim</Button>
                </Modal.Footer>
            </Modal>

            <h1 className="text-3xl text-black font-semibold mb-4">Estoque</h1>
            <input
                type="text"
                placeholder="Pesquisar por nome do produto"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
            />
            <div className="w-full">
                <Button onClick={() => setShowAddModal(true)}>+ Novo Item no Estoque</Button>
                <Button variant="success" className="ml-4 " onClick={() => setShowImportModal(true)}>Importar Estoque</Button>

                <DataTable
                    title="Lista de Produtos"
                    columns={columns}
                    data={filteredData}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 20, 50]}
                    customStyles={{
                        rows: {
                            style: {
                                backgroundColor: 'transparent',
                            },
                        },
                        headCells: {
                            style: {
                                backgroundColor: 'transparent',
                            },
                        },
                    }}
                />


            </div>
        </div>
    );

};

export default AdminStock;
