import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

type BlogPostProps = {
  id: number,
  title: string;
  content: string;
  image: string;
  creator_name: string;
  created_at: string;
  time_to_read: string;
};

const BlogPost: React.FC<BlogPostProps> = ({ id, title, content, image, creator_name, created_at, time_to_read }) => {
  return (
    <Link to={`blog/${id}`}>
      <div className="bg-[#0E233B] text-white p-4 rounded-lg shadow-lg flex flex-col duration-300 hover:scale-105 overflow-hidden mr-4">
        <img src={image} alt={title} className="rounded-lg w-full object-cover h-48" />
        <h3 className="text-2xl font-bold mt-4 mb-2">{title}</h3>
        <p className="text-sm mb-4" dangerouslySetInnerHTML={{ __html: content.replace(/<\/br>/g, "<br />").substring(0, 230).concat("...") }} />

        <div className="mt-auto">
          <p className="text-sm">{creator_name}</p>
          <p className="text-sm">
            {new Date(created_at).toLocaleDateString('pt-BR')} · {time_to_read} min de leitura
          </p>
        </div>
      </div>
    </Link>
  );
};

const BlogPosts: React.FC = () => {

  const [posts, setPosts] = useState<BlogPostProps[]>([]);

  useEffect(() => {
    axios.get('https://api.contasmurf.com/posts').then((result) => {
      setPosts(result.data)
    })
  }, [])

  return (
    <div id="blog" className="w-3/4 mx-auto my-16 text-white px-8">
      <h2 className="text-4xl text-center font-bold mb-10">Últimos posts no blog</h2>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        {posts.map((post, index) => (
          <BlogPost key={index} {...post} />
        ))}
      </div>
      <div className="text-center mt-8">

        <Link to='posts'>

          <button className="bg-[#8F8BF9] text-[#07172F] font-bold px-6 py-2 text-lg rounded transition duration-300 hover:border border-[#8F8BF9] hover:bg-[#07172F] hover:text-[#8F8BF9] hover:border-[#8F8BF9] transform hover:scale-105">
            Ver Mais Artigos
          </button>
        </Link>
      </div>
    </div>
  );
};

export default BlogPosts;
