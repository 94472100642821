import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { FaEdit, FaEnvelopeOpenText, FaSearch, FaTrash } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import axios from "axios";
import "./styles.css";
import { useNavigate } from "react-router-dom";

interface TableData {
    id: number;
    mercadopago_id: string;
    status: string;
    product_id: number;
    quantity: number
    total: number;
    delivery: boolean;
    created_at: string;
    updated_at: string;
    product: Product;
}
interface Product {
    id: number;
    name: string;
}

const AdminSales: React.FC = () => {
    const navigate = useNavigate();

    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [currentItem, setCurrentItem] = useState<TableData | null>(null);

    const [data, setData] = useState<TableData[]>([]);
    const [products, setProducts] = useState<Product[]>([]);

    const [dailySales, setDailySales] = useState({ count: 0, total: 0 });
    const [weeklySales, setWeeklySales] = useState({ count: 0, total: 0 });
    const [monthlySales, setMonthlySales] = useState({ count: 0, total: 0 });

    const removeCookie = (name: string) => {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
    };
    useEffect(() => {
        const checkToken = async () => {
            const token = document.cookie.split('; ').find(row => row.startsWith('userToken='));
            if (token) {
                try {
                    const tokenValue = token.split('=')[1];
                    const result = await axios.post('https://api.contasmurf.com/token/validate', { token: tokenValue });

                    if (result.status !== 200) {
                        removeCookie('userToken')
                        navigate('/admin')
                    }
                } catch (error) {
                    removeCookie('userToken')
                    navigate('/admin')
                }
            } else {
                navigate('/admin')
            }
        };
        checkToken();
    }, []);

    const token = document.cookie.split('; ').find(row => row.startsWith('userToken='))
    const tokenValue = token?.split('=')[1]

    const config = {
        headers: { Authorization: `Bearer ${tokenValue}` }
    };

    useEffect(() => {
        if (data.length > 0) {
            const formatDateToLocal = (dateString: any) => {
                const utcDate = new Date(dateString);
                const offset = 0;
                const localDate = new Date(utcDate.getTime() + offset * 3600 * 1000);
                const [year, month, day] = [localDate.getFullYear(), localDate.getMonth() + 1, localDate.getDate()].map(n => n.toString().padStart(2, '0'));
                return `${year}-${month}-${day}`;
            };
            const now = new Date();
            const todayString = formatDateToLocal(now);
            const lastSunday = new Date(now.setDate(now.getDate() - now.getDay()));
            const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

            const oneWeekAgoString = formatDateToLocal(lastSunday);
            const oneMonthAgoString = formatDateToLocal(firstDayOfMonth);

            const daily = data.filter(sale => sale.status === "Aprovado" && formatDateToLocal(sale.created_at) === todayString);
            const weekly = data.filter(sale => sale.status === "Aprovado" && formatDateToLocal(sale.created_at) >= oneWeekAgoString && formatDateToLocal(sale.created_at) < todayString);
            const monthly = data.filter(sale => sale.status === "Aprovado" && formatDateToLocal(sale.created_at) >= oneMonthAgoString && formatDateToLocal(sale.created_at) < todayString);

            setDailySales({
                count: daily.length,
                total: daily.reduce((acc, sale) => acc + sale.total, 0)
            });

            setWeeklySales({
                count: weekly.length,
                total: weekly.reduce((acc, sale) => acc + sale.total, 0)
            });

            setMonthlySales({
                count: monthly.length,
                total: monthly.reduce((acc, sale) => acc + sale.total, 0)
            });
        }
    }, [data]);




    useEffect(() => {
        axios.get('https://api.contasmurf.com/sales', config).then((result) => {
            setData(result.data)
        })
        axios.get('https://api.contasmurf.com/products', config).then((result) => {
            setProducts(result.data)
        })
    }, [])

    const handleEdit = (item: any) => {
        setCurrentItem(item);
        setShowEditModal(true);
    };

    const handleDelete = (item: any) => {
        setCurrentItem(item);
        setShowDeleteModal(true);
    };

    const handleSaveEdit = () => {
        axios.patch(`https://api.contasmurf.com/sales/${currentItem?.id}`, {
            mercadopago_id: currentItem?.mercadopago_id,
            status: currentItem?.status,
            product_id: currentItem?.product.id,
            delivery: currentItem?.delivery,
            quantity: currentItem?.quantity,
            total: currentItem?.total
        }, config).then((response) => {
            if (response.status === 200) {
                setShowEditModal(false);
                setCurrentItem(null)
                toast.success("Venda editada com sucesso!");
                axios.get('https://api.contasmurf.com/sales', config).then((result) => {
                    setData(result.data)
                })
            } else {
                toast.error("Um erro ocorreu ao atualizar (STATUS: " + response.status + ")")
            }
        })
    };
    const confirmDelete = () => {
        axios.delete(`https://api.contasmurf.com/sales/${currentItem?.id}`, config).then((result) => {
            if (result.status === 204) {
                toast.error("Venda excluída com sucesso!");
                setData(data.filter(item => item.id !== currentItem?.id))
            } else {
                toast.error("Erro ao excluir a venda!");
            }
        })
        setCurrentItem(null)
        setShowDeleteModal(false)
    };

    const columns = [
        {
            name: "ID",
            selector: (row: TableData) => row.id.toString(),
        },
        {
            name: "MercadoPago ID",
            selector: (row: TableData) => row.mercadopago_id.toString(),
        },
        {
            name: "Status",
            selector: (row: TableData) => row.status,
        },
        {
            name: "Produto",
            selector: (row: TableData) => row.product.name,
        },
        {
            name: "Quantidade",
            selector: (row: TableData) => row.quantity.toString(),
        },
        {
            name: "Total (R$)",
            selector: (row: TableData) => "R$ " + row.total.toString(),
        },
        {
            name: "Entrega Realizada?",
            selector: (row: TableData) => row.delivery ? 'Sim' : 'Não',
        },
        {
            name: "Data de Criação",
            selector: (row: TableData) => {
                const dateString = row.created_at;
                const date = new Date(dateString);
                const formattedDate = `${("0" + date.getDate()).slice(-2)}/${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()} ${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}`;
                return formattedDate;
            },
        },
        {
            name: "Ações",
            cell: (row: TableData) => (
                <div className="space-x-2">
                    <button
                        onClick={() => handleEdit(row)}
                        className="text-blue-600 hover:text-blue-800"
                    >
                        <FaEdit />
                    </button>
                    <button
                        onClick={() => handleDelete(row)}
                        className="text-red-600 hover:text-red-800"
                    >
                        <FaTrash />
                    </button>

                    {row.status === "Aprovado" && (
                        <>
                            <button
                                onClick={() => handleShowSaleDetail(row)}
                                className="text-green-600 hover:text-green-800"
                            >
                                <FaSearch />
                            </button>
                            <button
                                onClick={() => handleShowEmail(row)}
                                className="text-yellow-600 hover:text-yellow-800"
                            >
                                <FaEnvelopeOpenText />
                            </button>
                        </>
                    )}
                </div>
            ),
        },
    ];

    const handleShowSaleDetail = (row: any) => {
        setEmail("")
        setProductStockItems([])
        setShowViewModal(true);
        setProductName(row.product.name);
        setSaleId(row.id)
        axios.get(`https://api.contasmurf.com/sales/details/${row.id}`, config).then((result) => {
            setEmail(result.data.email);
            setProductStockItems(result.data.items);
        })
    };
    
    const handleShowEmail = (row: any) => {
        setEmail("")
        setProductStockItems([])
        setShowEmailModal(true)
        setProductName(row.product.name)
        setSaleId(row.id)
        axios.get(`https://api.contasmurf.com/sales/details/${row.id}`, config).then((result) => {
            setEmail(result.data.email);
            setProductStockItems(result.data.items);
        })
    }

    const handleSendEmail = (sale_id: any) => {
        setShowEmailModal(false)
        axios.post(`https://api.contasmurf.com/sales/emailsender/${sale_id}`, { email: email }, config).then((result) => {
            toast.success("E-mail enviado com sucesso!");
        }).catch(() => {
            toast.error("Erro ao enviar o e-mail!");

        })
    }

    const [email, setEmail] = useState("")
    const [saleId, setSaleId] = useState("")


    const [productName, setProductName] = useState("")
    const [searchText, setSearchText] = useState("");

    const filteredData = data.filter((item: TableData) =>
        item?.product?.name.toLowerCase().includes(searchText.toLowerCase())
    );
    const [productStockItems, setProductStockItems] = useState([]);
    const [showViewModal, setShowViewModal] = useState(false)
    const [showEmailModal, setShowEmailModal] = useState(false)

    return (
        <div className="w-full h-full flex flex-col items-center">

            {/* Modal de Visualização de Compra */}
            <Modal show={showViewModal} onHide={() => setShowViewModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Detalhes da Venda</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formProductName">
                            <Form.Label>Produto Adquirido</Form.Label>
                            <Form.Control
                                type="text"
                                value={productName}
                                readOnly
                                style={{ cursor: "not-allowed" }}
                            />
                            {productStockItems.map((item: any, index) => (
                                <div key={index}>
                                    <Form.Label className="pt-4">Produto Entregue - {index + 1}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={`ID: ${item.stockId}`}
                                        readOnly
                                        style={{ cursor: "not-allowed" }}
                                    />
                                    <Form.Control
                                        type="text"
                                        value={item.stockItem}
                                        readOnly
                                        style={{ cursor: "not-allowed" }}
                                    />
                                </div>
                            ))}
                            <Form.Label className="pt-4">E-mail utilizado pelo Usuário</Form.Label>
                            <Form.Control
                                type="email"
                                value={email}
                                readOnly
                                style={{ cursor: "not-allowed" }}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Modal de Reenvio de E-mail */}
            <Modal show={showEmailModal} onHide={() => setShowEmailModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Reenviar e-mail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formProductName">
                            <Form.Label>Produto Adquirido</Form.Label>
                            <Form.Control
                                type="text"
                                value={productName}
                                readOnly
                                style={{ cursor: "not-allowed" }}
                            />
                            {productStockItems.map((item: any, index) => (
                                <div key={index}>
                                    <Form.Label className="pt-4">Produto para ser Entregue - {index + 1}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={`ID: ${item.stockId}`}
                                        readOnly
                                        style={{ cursor: "not-allowed" }}
                                    />
                                    <Form.Control
                                        type="text"
                                        value={item.stockItem}
                                        readOnly
                                        style={{ cursor: "not-allowed" }}
                                    />
                                </div>
                            ))}
                            <Form.Label className="pt-4">E-mail para envio</Form.Label>
                            <Form.Control
                                type="email"
                                defaultValue={email}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEmailModal(false)}>Cancelar</Button>
                    <Button variant="primary" onClick={() => handleSendEmail(saleId)}>Enviar E-mail</Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de Edição */}
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Venda</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {/* Exemplo de campo preenchido - adapte conforme necessário */}
                        <Form.Group className="mb-3" controlId="formProductName">

                            <Form.Label>ID do MercadoPago</Form.Label>
                            <Form.Control onChange={(e) => {
                                if (currentItem) {
                                    setCurrentItem({ ...currentItem, mercadopago_id: e.target.value })
                                }
                            }} type="text" placeholder="Produto" defaultValue={currentItem?.mercadopago_id} />

                            <Form.Label>Status</Form.Label>
                            <Form.Control onChange={(e) => {
                                if (currentItem) {
                                    setCurrentItem({ ...currentItem, status: e.target.value })
                                }
                            }} type="text" placeholder="Produto" defaultValue={currentItem?.product.name} />

                            <Form.Label>Produto</Form.Label>
                            <Form.Select onChange={(e) => {
                                const selectedProduct = products.find(product => product.name === e.target.value);
                                if (currentItem && selectedProduct) {
                                    setCurrentItem({
                                        ...currentItem,
                                        product: { ...currentItem.product, id: selectedProduct.id }
                                    });
                                }
                            }} defaultValue={currentItem?.product.name || ''}>
                                {products.map((product) => (
                                    <option key={product.id} value={product.name}>{product.name}</option>
                                ))}
                            </Form.Select>

                            <Form.Label>Quantidade</Form.Label>
                            <Form.Control onChange={(e) => {
                                if (currentItem) {
                                    setCurrentItem({ ...currentItem, quantity: Number.parseInt(e.target.value) })
                                }
                            }} type="number" placeholder="Quantidade" defaultValue={currentItem?.quantity} />

                            <Form.Label>Total</Form.Label>
                            <Form.Control onChange={(e) => {
                                if (currentItem) {
                                    setCurrentItem({ ...currentItem, total: Number.parseInt(e.target.value) })
                                }
                            }} type="number" placeholder="Total" defaultValue={currentItem?.total} />

                            <Form.Label>Entrega Realizada?</Form.Label>
                            <Form.Select onChange={(e) => {
                                if (currentItem) {
                                    setCurrentItem({ ...currentItem, delivery: e.target.value === '1' ? true : false })
                                }
                            }} defaultValue={currentItem?.delivery ? '1' : '0'}>
                                <option value="0">Não</option>
                                <option value="1">Sim</option>
                            </Form.Select>
                        </Form.Group>
                        {/* Adicione mais campos conforme necessário */}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>Cancelar</Button>
                    <Button variant="primary" onClick={handleSaveEdit}>Salvar Alterações</Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de Confirmação de Exclusão */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmação de Exclusão</Modal.Title>
                </Modal.Header>
                <Modal.Body>Tem certeza que deseja excluir essa venda?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Não</Button>
                    <Button variant="danger" onClick={confirmDelete}>Sim</Button>
                </Modal.Footer>
            </Modal>

            <h1 className="text-3xl text-black font-semibold mb-4">Vendas</h1>
            <div className="w-full bg-black mb-10" style={{ height: '1px' }}>.</div>

            <div className="w-full text-black flex justify-around text-center mb-4">
                <div>
                    <h3 className="font-bold">Vendas do Dia</h3>
                    <p>{dailySales.count} vendas</p>
                    <p>R$ {dailySales.total.toFixed(2)}</p>
                </div>
                <div>
                    <h3 className="font-bold">Vendas da Semana</h3>
                    <p>{weeklySales.count} vendas</p>
                    <p>R$ {weeklySales.total.toFixed(2)}</p>
                </div>
                <div>
                    <h3 className="font-bold">Vendas do Mês</h3>
                    <p>{monthlySales.count} vendas</p>
                    <p>R$ {monthlySales.total.toFixed(2)}</p>
                </div>
            </div>

            <input
                type="text"
                placeholder="Pesquisar por nome do produto"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
            />
            <div className="w-full">
                <DataTable
                    title="Lista de Vendas"
                    columns={columns}
                    data={filteredData}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 20, 50]}
                    customStyles={{
                        rows: {
                            style: {
                                backgroundColor: 'transparent',
                            },
                        },
                        headCells: {
                            style: {
                                backgroundColor: 'transparent',
                            },
                        },
                    }}
                />


            </div>
        </div>
    );

};

export default AdminSales;
