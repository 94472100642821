import React, { useEffect, useState } from "react";
import { FaHome, FaMoneyBillWave, FaCogs, FaBoxes, FaSignOutAlt, FaUserCircle } from "react-icons/fa";
import AdminSales from "../../components/AdminSales";
import AdminProducts from "../../components/AdminProducts";
import AdminPosts from "../../components/AdminPosts";
import AdminStock from "../../components/AdminStock";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AdminPanel = () => {
  const navigate = useNavigate();
  const [activeOption, setActiveOption] = useState("Vendas");
  const [logged, setLoggedt] = useState(false);

  const username = "admin@contasmurf.com.br";

  const handleOptionClick = (option: string) => {
    setActiveOption(option);
  };

  const removeCookie = (name: string) => {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
  };
  useEffect(() => {
    const checkToken = async () => {
      const token = document.cookie.split('; ').find(row => row.startsWith('userToken='));
      if (token) {
        try {
          const tokenValue = token.split('=')[1];
          const result = await axios.post('https://api.contasmurf.com/token/validate', { token: tokenValue });
          if (result.status === 200) {
            setLoggedt(true);
          }
          if (result.status !== 200) {
            removeCookie('userToken')
            navigate('/admin')
          }
        } catch (error) {
          removeCookie('userToken')
          navigate('/admin')
        }
      } else {
        navigate('/admin')
      }
    };
    checkToken();
  }, []);

  return (
    <>
      {logged && (
        <div className="flex h-screen bg-[#ffffff] text-white">
          {/* Sidebar */}
          <nav className="w-64 bg-gradient-to-r bg-[#113366] p-4 fixed top-0 left-0 h-screen overflow-y-auto">
            <div className="flex items-center mb-8">
              <FaUserCircle className="mr-2 text-4xl" />
              <span className="text-2xl font-semibold">Painel ADM</span>
            </div>
            <div className="mb-8">
              <div className="flex items-center">
                <span className="mr-2 text-sm font-bold">{username}</span>
              </div>
            </div>
            <ul className="text-gray-300">
              {["Vendas", "Produtos", "Estoque", "Posts"].map((option) => (
                <li
                  key={option}
                  className={`mt-2 text-sm cursor-pointer px-2 py-1 rounded-md ${activeOption === option
                    ? "bg-blue-500 text-white"
                    : "hover:bg-blue-800 hover:text-white"
                    }`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option}
                </li>
              ))}
            </ul>
            <div className="mt-auto">
              <button onClick={() => {
                removeCookie('userToken')
                navigate('/admin')
              }} className="flex items-center w-full text-left hover:bg-blue-800 p-2 rounded-lg focus:outline-none transition duration-300 ease-in-out">
                <FaSignOutAlt className="mr-2" />
                Sair
              </button>
            </div>
          </nav>

          {/* Conteúdo */}
          <div className="flex-1 ml-64 p-6 overflow-y-auto">
            {/* Conteúdo do painel aqui */}
            {activeOption === "Estoque" && (
              <AdminStock />
            )}
            {activeOption === "Vendas" && (
              <AdminSales />
            )}
            {activeOption === "Produtos" && (
              <AdminProducts />
            )}
            {activeOption === "Posts" && (
              <AdminPosts />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AdminPanel;
